@import 'src/styles/variables';

.heading {
  text-align: center;
  margin: 40px 0 20px 0;
  margin-bottom: 2rem;
}

.instituteName {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: row;
 gap: 1rem;
}

.text{
  text-align: center !important;
  font-family: 'Open Sans' !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.8rem !important;
  @include breakpoints-down($phone-lg) {
    display: block !important;
    font-size: 15px !important;
    padding: 0 1rem !important;
    line-height: 1.5 !important;
    margin: 1rem auto !important;
  }
}

.link{
  text-decoration: none;
}